@font-face {
  font-family: "iconfont"; /* Project id 4415216 */
  src: url('iconfont.woff2?t=1705564735867') format('woff2'),
       url('iconfont.woff?t=1705564735867') format('woff'),
       url('iconfont.ttf?t=1705564735867') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-mail:before {
  content: "\e610";
}

.icon-phone:before {
  content: "\e628";
}

.icon-kefu:before {
  content: "\ec2e";
}

.icon-huidaodingbu:before {
  content: "\e622";
}

