.headerBox {
  width: 1200px;
  display: flex;
  height: 64px;
}

.logoBox {
  width: 150px;
  color: black;
  background-color: #fff;
  display: flex;
  align-items: center;
  margin-right: 54px;

  img {
    justify-content: center;
    background-size: cover;
  }

}

.slider {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.slides {
  display: flex;
  animation: slide 20s linear infinite;
}

.slidesTwo {
  display: flex;
  animation: slidesTwo 20s linear infinite;
}

.slides img {
  width: 100%;
  height: auto;
  margin-right: 10px;
}

.slidesTwo img {
  width: 100%;
  height: auto;
  margin-right: 10px;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }

  16.666% {
    transform: translateX(-16.666%);
  }

  33.333% {
    transform: translateX(-33.333%);
  }

  50% {
    transform: translateX(-50%);
  }

  66.666% {
    transform: translateX(-33.333%);
  }

  83.333% {
    transform: translateX(-16.666%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slidesTwo {
  0% {
    transform: translateX(0);
  }

  16.666% {
    transform: translateX(16.666%);
  }

  33.333% {
    transform: translateX(33.333%);
  }

  50% {
    transform: translateX(50%);
  }

  66.666% {
    transform: translateX(33.333%);
  }

  83.333% {
    transform: translateX(16.666%);
  }

  100% {
    transform: translateX(0);
  }
}


.bottomNav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  // color: white;
  position: relative;
  min-width: 200px; /* 设置一个最小宽度 */
}

.bottomNav a {
  color: black;
  text-decoration: none;
  padding: 10px;
  cursor: pointer;
  outline: none; 
}
.bottomNav a:hover,
.bottomNav a.selected {
  color: #226FFF;
}

